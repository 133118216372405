import React, { useContext, useState } from "react"
import styled from "@emotion/styled"
import { color, formStyles, breakpoints } from "../../../utils/style"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { registerUrl } from "../Config.js"
import IcomoonIcon from "../../shared/IcomoonIcon"
import StoreContext from "../../shared/Context/StoreContext"
import PopupWindow from "../../shared/PopupWindow"
import LinePopupContent from "../../Content/Register/LinePopupContent"
import GetWindowWidth from "../../shared/GetWindowWidth"

const Wrapper = styled("div")`
  ${formStyles}
  position: relative;
  padding: 0 0 20px;
  margin-top: 10px;
  text-align: left;
  .loading {
    position: relative;
  }
  .loading::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }
  .two-fields {
    flex-basis: 70%;
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
      &:first-of-type {
        margin-right: 4%;
      }
    }
  }
  .button {
    float: right;
    svg {
      margin-left: 5px;
    }
  }
  .accordion {
    margin: 0;
    position: relative;
    .accordion-title {
      margin-bottom: 5px;
      padding: 0 0 20px;
      &:after {
        left: auto;
        right: 20px;
      }
    }
    .accordion-title:hover {
      &:after {
        border-color: ${color.gold};
      }
    }
    .accordion-content {
      background: ${color.black};
      margin-top: -20px;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      overflow: hidden;
      height: auto;
      padding: 5px 0 0;
      width: 100%;
      padding: 10px 20px;
      ul {
        list-style: none;
      }
      li {
        border-bottom: 1px solid ${color.white};
      }
      .a {
        background: transparent;
        color: ${color.white};
        border: none;
        font-size: 16px;
        padding: 10px 0;
      }
    }
  }
  .bottom-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .register-success {
    .popup-content-wrapper {
      margin-top: 14%;
    }
    .title {
      margin: 0 0 8px;
      font-weight: 700;
      font-size: 22px;
      line-height: 32px;
      color: ${color.orange};
      text-transform: uppercase;
    }
    p {
      margin: 0;
      font-size: 15px;
      line-height: 28px;
      color: #e0e0e0;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    .bottom-wrap {
      display: block;
    }
    .button {
      float: none;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .two-fields {
      .fieldWrap {
        width: 100%;
        padding-bottom: 10px;
        &:first-of-type {
          margin-right: 0;
        }
      }
    }
    .button {
      width: 100%;
    }
  }
`

const MsgWrap = styled("div")`
  span {
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
    border-radius: 5px;

    @media (max-width: ${breakpoints.sm}) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      padding: 20px;
      border-radius: 0;
    }

    &.errorMsg {
      display: block;
      margin-bottom: 30px;
    }
  }
`

const FormWrap = styled("div")`
  background: rgba(255, 255, 255, 0.08);
  padding: 48px 24px 32px;
  border-radius: 24px;
  .form-title {
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    text-transform: uppercase;
    color: ${color.gold};
  }
  .row {
    display: flex;
  }
  .col {
    flex-basis: 50%;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 24px 16px;
    .row {
      display: block;
    }
  }
`

const initialValues = {
  username: "",
  email: "",
  phone: "",
  password: "",
  register_type: { real_money: false, free_speen: false },
}

// Checkbox input
const Checkbox = ({
  field: { name, value, onChange, onBlur },
  //form: { errors, touched, setFieldValue },
  id,
  label,
  //...props
}) => {
  return (
    <div className="minimal-custom-checkbox">
      <input
        name={name}
        id={id}
        type="checkbox"
        value={value}
        checked={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

const FormBody = () => {
  const [errorMessage, setErrorMessage] = useState("")

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    values.domain = window.location.origin //add domain, used to be location.host
    let body = JSON.stringify(values)
    fetch(registerUrl, {
      method: "post",
      body: body,
    })
      .then(function (response) {
        return response.text()
      })
      .then(function (body) {
        let data = JSON.parse(body)
        const popupWindow = document.getElementById("register-success")
        if (data.result * 1 === 0 && data.message) {
          setErrorMessage(data.message)
          setSubmitting(false)
        } else {
          resetForm({})
          setErrorMessage("")
          popupWindow.classList.add("popup-open")
        }
      })
      .catch(function (error) {
        console.log("Send Request failed", error)
      })
  }

  const validateRegistration = values => {
    let errors = {}
    if (!(values.register_type.real_money || values.register_type.free_speen)) {
      errors.register_type = "Please select at least one"
    }
    return errors
  }

  const windowWidth = GetWindowWidth()

  const hideNotificationOnMobile = () => {
    if (windowWidth <= 600) setErrorMessage("")
  }

  return (
    <Wrapper>
      <MsgWrap>
        {errorMessage && (
          <span
            role="button"
            tabIndex={0}
            className="errorMsg"
            onClick={hideNotificationOnMobile}
            onKeyDown={hideNotificationOnMobile}
          >
            {errorMessage}
          </span>
        )}
      </MsgWrap>
      <PopupWindow popupId="register-line-popup">
        <LinePopupContent />
      </PopupWindow>
      <PopupWindow popupId="register-success" extraClass="register-success">
        <div className={"title"}>Account Successfully Created</div>
        <p>Your free spin account was created.</p>
        <p>
          To start playing please activate your account with the link we sent on
          your mail
        </p>
      </PopupWindow>
      <FormWrap>
        <h2 className="form-title">Please fill in all fields</h2>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validateRegistration}
          validationSchema={Yup.object().shape({
            username: Yup.string().required("จำเป็นต้องระบุ"),
            email: Yup.string().email().required("จำเป็นต้องระบุ"),
            password: Yup.string().required("จำเป็นต้องระบุ"),
            phone: Yup.string()
              .max(12, "กรุณากรอกตัวเลข 12 หลัก")
              .required("จำเป็นต้องระบุ"),
            line: Yup.string().required("จำเป็นต้องระบุ"),
          })}
        >
          {({ errors, status, touched, isSubmitting, isValid, values }) => (
            <Form id="regStaticForm">
              <div className="row">
                <div className="col">
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="user" color={color.white} size={18} />
                    {errors.username && touched.username && (
                      <div className="error-new">! {errors.username}</div>
                    )}
                    <Field
                      type="text"
                      name="username"
                      placeholder="ยูสเซอร์เนม"
                      id="register-username-static"
                      value={values.username || ""}
                    />
                  </div>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="mail" color={color.white} size={18} />
                    {errors.email && touched.email && (
                      <div className="error-new">! {errors.email}</div>
                    )}
                    <Field
                      type="email"
                      name="email"
                      placeholder="อีเมล"
                      value={values.email || ""}
                    />
                  </div>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="pass" color={color.white} size={20} />
                    {errors.password && touched.password && (
                      <div className="error-new">! {errors.password}</div>
                    )}
                    <Field
                      type="password"
                      name="password"
                      placeholder="รหัสผ่าน"
                      id="register-password-static"
                      autoComplete="on"
                      value={values.password || ""}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="mobile" color={color.white} size={18} />
                    {errors.phone && errors.phone && touched.phone && (
                      <div className="error-new">! {errors.phone}</div>
                    )}
                    <Field
                      type="text"
                      name="phone"
                      placeholder="เบอร์มือถือที่ติดต่อได้"
                      value={values.phone || ""}
                    />
                  </div>
                  <div className="fieldWrap iconField">
                    <IcomoonIcon icon="line" color={color.white} size={22} />
                    {errors.line && touched.line && (
                      <div className="error-new">! {errors.line}</div>
                    )}
                    <Field
                      type="text"
                      name="line"
                      placeholder="LINE ID"
                      value={values.line || ""}
                    />
                  </div>
                </div>
              </div>
              <div className="bottom-wrap">
                <div className="checkboxGroupWrap two-fields">
                  <p>เกมที่คุณสนใจ:</p>

                  <div className="fieldWrap">
                    <Field
                      component={Checkbox}
                      name="register_type[free_speen]"
                      id="register-type-free-speen-static"
                      label="สมัครเล่น สล็อตฟรีสปิน"
                      value={
                        values.register_type && values.register_type.free_speen
                          ? values.register_type.free_speen
                          : false
                      }
                    />
                  </div>

                  <div className="fieldWrap">
                    <Field
                      component={Checkbox}
                      name="register_type[real_money]"
                      id="register-type-real-money-static"
                      label="สมัครเล่นคาสิโน"
                      value={
                        values.register_type && values.register_type.real_money
                          ? values.register_type.real_money
                          : false
                      }
                    />
                  </div>

                  {Object.keys(errors).length === 1 && errors.register_type && (
                    <div className="error">! {errors.register_type}</div>
                  )}
                </div>

                {status && status.msg && <div>{status.msg}</div>}
                <button
                  aria-label="Submit"
                  className="button transparent-button"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  <span>สมัครสมาชิก</span>
                  <IcomoonIcon
                    icon="arrow-right"
                    color={color.white}
                    size={16}
                  />
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </FormWrap>
    </Wrapper>
  )
}

const RegistrationFormStatic = ({ games }) => {
  const value = useContext(StoreContext)
  const username = value.username
  return !username ? (
    <React.Fragment>
      <FormBody games={games} />
    </React.Fragment>
  ) : (
    ""
  )
}

export default RegistrationFormStatic
