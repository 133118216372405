import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEOPage"
import styled from "@emotion/styled"
import {
  wrapper1200,
  color,
  breakpoints,
  wrapper,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import RegistrationFormStatic from "../../components/Auth/Form/RegistrationFormStaticCastrated"

const NoFeaturedWrapperReg = styled("div")`
  border-radius: 16px;
  padding: 64px 32px;
  background: rgb(26, 24, 36);
  background: radial-gradient(
    circle,
    rgba(26, 24, 36, 1) 0%,
    rgba(21, 2, 5, 1) 100%
  );
  margin-bottom: 48px;
  h1 {
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-shadow: ${color.goldTextShadow};
    margin: 0 0 24px;
  }
  .top-description {
    font-size: 16px;
    line-height: 24px;
  }
  @media (max-width: ${breakpoints.lg}) {
    .top-block {
      flex-basis: 100%;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 32px 16px 24px;
  }
  @media (max-width: ${breakpoints.sm}) {
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 16px;
    }
  }
`

const WrapperDesktop = styled("div")`
  ${wrapper1200}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;
  .flex {
    img {
      border-radius: 28px;
    }
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const currentPage = data.wordpressPage
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const games = data.allWordpressFreespinGameslist.edges
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const content_field_one = currentPage.acf.content_field_01
  const content_field_two = currentPage.acf.content_field_02
  const content_field_tree = currentPage.acf.content_field_03
  const content_field_four = currentPage.acf.content_field_04
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <NoFeaturedWrapperReg>
          <div className="top-block">
            <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
            {currentPage.acf && currentPage.acf.top_register_text && (
              <p className="top-description">
                {currentPage.acf.top_register_text}
              </p>
            )}
          </div>
        </NoFeaturedWrapperReg>

        <div className="top-block top-block-right">
          <RegistrationFormStatic games={games} />
        </div>
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          {content_field_one && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: content_field_one }} />
            </div>
          )}
          {content_field_two && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: content_field_two }} />
            </div>
          )}
          {content_field_tree && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: content_field_tree }} />
            </div>
          )}
          {content_field_four && (
            <div>
              <span dangerouslySetInnerHTML={{ __html: content_field_four }} />
            </div>
          )}
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_register_text
        content_field_01
        content_field_02
        content_field_03
        content_field_04
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressWpMedia(filter: { wordpress_id: { in: $contentImages } }) {
      edges {
        node {
          wordpress_id
          alt_text
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
    allWordpressFreespinGameslist {
      edges {
        node {
          code
          name
        }
      }
    }
  }
`
